export interface Validation {

}

export const validation = (values: Validation) => {
    let errors: Validation = {};

    return errors
}

export default validation